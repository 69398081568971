export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type ApplyForCredentialInput = {
  reason: Scalars['String']['input'];
};

export type ApplyForFundingInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type Asset = {
  __typename?: 'Asset';
  extension?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sizeInBytes?: Maybe<Scalars['Int']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  type: AssetType;
  url: Scalars['String']['output'];
};

/** List of asset types */
export enum AssetType {
  Image = 'IMAGE',
  Other = 'OTHER',
  Video = 'VIDEO'
}

export type BalanceSummary = BalanceSummaryInterface & {
  __typename?: 'BalanceSummary';
  /** Represented in cents (e.g. $10 is 1000). */
  availableAmount: Scalars['Int']['output'];
  /** The same as the current user id, useful for client-side caching purposes. */
  id: Scalars['String']['output'];
  /** Represented in cents (e.g. $10 is 1000). */
  pendingAmount: Scalars['Int']['output'];
  /** The total amount of money withdrawn. Represented in cents (e.g. $10 is 1000). */
  withdrawalAmount: Scalars['Int']['output'];
};

export type BalanceSummaryInterface = {
  /** The same as the current user id, useful for client-side caching purposes. */
  id: Scalars['String']['output'];
};

export type Conversation = ConversationInterface & {
  __typename?: 'Conversation';
  favorited: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lastMessage?: Maybe<Message>;
  otherParticipants: Array<User>;
  read: Scalars['Boolean']['output'];
  sortBy: Scalars['DateTime']['output'];
};

export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  edges: Array<ConversationEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  /** The node object (belonging to type Conversation) attached to the edge. */
  node: Conversation;
};

export enum ConversationFilter {
  Favorited = 'FAVORITED',
  Unread = 'UNREAD'
}

export type ConversationInterface = {
  favorited: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
  sortBy: Scalars['DateTime']['output'];
};

export type ConversationMessagesConnection = {
  __typename?: 'ConversationMessagesConnection';
  edges: Array<ConversationMessagesEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type ConversationMessagesEdge = {
  __typename?: 'ConversationMessagesEdge';
  /** The node object (belonging to type Message) attached to the edge. */
  node: Message;
};

export type CreateAssetInput = {
  extension?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remoteId: Scalars['String']['input'];
  sizeInBytes?: InputMaybe<Scalars['Int']['input']>;
  type: AssetType;
};

export type CreatePostCommentInput = {
  /** The id of the parent postComment if creating a threaded reply. */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the post to create a postComment for. */
  postId: Scalars['String']['input'];
  /** The postComment text. */
  text: Scalars['String']['input'];
};

export type CreatePostInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Schedule when the post should expire. Set to `null` to keep the post live indefinitely. */
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Schedule when the post should get published. Set to `null` to keep the post in a draft state in which no other users will see the post. */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductInput = {
  /** 1 or more images required. Only images are supported. */
  assetIds: Array<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  skus: Array<CreateSkuInput>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateSellerOnboardingLinkInput = {
  /** The url to redirect to after the onboarding is complete. */
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSkuInput = {
  /** Digital assets for download upon purchase. */
  digitalAssetIds: Array<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  /** Represented in cents (e.g. $10 is 1000) */
  price: Scalars['Int']['input'];
  status: SkuStatus;
  title: Scalars['String']['input'];
};

export type CreateStripeCheckoutSessionInput = {
  /** The URL to redirect to if the user cancels. Required when `type` is `STRIPE_HOSTED_PAGE`. */
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  /** (optional) The post that is initiating the sale. If provided, and the sale is successful, the post will be updated with the sale analytics. */
  postId?: InputMaybe<Scalars['String']['input']>;
  skuId: Scalars['String']['input'];
  /** The URL to redirect to if the user successfully completes the payment, regardless of `type`. */
  successUrl: Scalars['String']['input'];
  type: StripeCheckoutSessionType;
};

export type CreateStripePaymentIntentInput = {
  /** (optional) The post that is initiating the sale. If provided, and the sale is successful, the post will be updated with the sale analytics. */
  postId?: InputMaybe<Scalars['String']['input']>;
  skuId: Scalars['String']['input'];
};

export type FollowConnection = {
  __typename?: 'FollowConnection';
  edges: Array<FollowEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type FollowEdge = {
  __typename?: 'FollowEdge';
  /** The node object (belonging to type User) attached to the edge. */
  node: User;
};

export type LeaderboardStat = {
  __typename?: 'LeaderboardStat';
  /** Same as the underlying userId */
  id: Scalars['String']['output'];
  totalLikeCount: Scalars['Float']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type LimitOffsetPageInfo = {
  __typename?: 'LimitOffsetPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalEdges: Scalars['Int']['output'];
};

export type Message = MessageInterface & {
  __typename?: 'Message';
  assetIds: Array<Scalars['String']['output']>;
  assets: Array<Asset>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  /** The sender of the message. */
  user: User;
  userId: Scalars['String']['output'];
};

export type MessageInterface = {
  assetIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type MessageSummary = {
  __typename?: 'MessageSummary';
  hasNewMessages: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  applyForCredential: Scalars['Boolean']['output'];
  /** Apply for funding. Returns the application ID. */
  applyForFunding: Scalars['String']['output'];
  createAsset: Asset;
  createConversation?: Maybe<Conversation>;
  createPost?: Maybe<Post>;
  createPostComment?: Maybe<PostComment>;
  createProduct: Product;
  createSellerOnboardingLink: Scalars['String']['output'];
  createStripeCheckoutSession: StripeCheckoutSession;
  createStripePaymentIntent: StripePaymentIntent;
  deleteAccount: Scalars['String']['output'];
  /** Deletes all messages in a conversation for the current user only. */
  deleteConversationMessages?: Maybe<Scalars['String']['output']>;
  /** Deletes a message for the current user only. */
  deleteMessage?: Maybe<Scalars['String']['output']>;
  deletePost?: Maybe<Scalars['String']['output']>;
  deletePostComment?: Maybe<Scalars['String']['output']>;
  followUser?: Maybe<User>;
  freezeAccount: Scalars['String']['output'];
  likePost?: Maybe<Post>;
  /** Resets `messageSummary.hasNewMessages` back to false. */
  markHasNoNewMessages: MessageSummary;
  register: User;
  reportPost?: Maybe<Scalars['String']['output']>;
  reportPostComment?: Maybe<Scalars['String']['output']>;
  sendMessage?: Maybe<Message>;
  unfollowUser?: Maybe<Scalars['String']['output']>;
  unfreezeAccount: User;
  unlikePost?: Maybe<Post>;
  updateConversation?: Maybe<Conversation>;
  updateMe: User;
  updatePost?: Maybe<Post>;
  updateSelectedUser: User;
  updateSku: Sku;
  withdraw?: Maybe<WithdrawalWithPayoutLink>;
};


export type MutationApplyForCredentialArgs = {
  input: ApplyForCredentialInput;
};


export type MutationApplyForFundingArgs = {
  input: ApplyForFundingInput;
};


export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};


export type MutationCreateConversationArgs = {
  recipientUserId: Scalars['String']['input'];
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreatePostCommentArgs = {
  input: CreatePostCommentInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateSellerOnboardingLinkArgs = {
  input: CreateSellerOnboardingLinkInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  input: CreateStripeCheckoutSessionInput;
};


export type MutationCreateStripePaymentIntentArgs = {
  input: CreateStripePaymentIntentInput;
};


export type MutationDeleteConversationMessagesArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePostArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePostCommentArgs = {
  id: Scalars['String']['input'];
};


export type MutationFollowUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationLikePostArgs = {
  id: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  data: RegisterUserInput;
};


export type MutationReportPostArgs = {
  id: Scalars['String']['input'];
  input: ReportPostInput;
};


export type MutationReportPostCommentArgs = {
  id: Scalars['String']['input'];
  input: ReportPostCommentInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationUnfollowUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnlikePostArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateConversationArgs = {
  id: Scalars['String']['input'];
  input: UpdateConversationInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateUserInput;
};


export type MutationUpdatePostArgs = {
  id: Scalars['String']['input'];
  input: UpdatePostInput;
};


export type MutationUpdateSelectedUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateSkuArgs = {
  id: Scalars['String']['input'];
  input: UpdateSkuInput;
};


export type MutationWithdrawArgs = {
  input: WithdrawInput;
};

export type Order = OrderInterface & {
  __typename?: 'Order';
  /** The amount paid by the user. Represented in cents (e.g. $10 is 1000) */
  amount: Scalars['Int']['output'];
  buyer: User;
  buyerId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The date the order was delivered to the buyer. */
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** Only visible to the seller, otherwise returns 0. Represented in cents (e.g. $10 is 1000) */
  feeAmount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  /** Only visible to the seller, otherwise returns 0. Represented in cents (e.g. $10 is 1000) */
  payoutAmount: Scalars['Int']['output'];
  /** Only visible to the seller, otherwise returns `null`. The date the payout is available to the seller. Returns `null` for physical goods that haven't delivered yet. */
  payoutAvailableAt?: Maybe<Scalars['DateTime']['output']>;
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['String']['output']>;
  /** The amount of money refunded to the buyer. Represented in cents (e.g. $10 is 1000) */
  refundedAmount: Scalars['Int']['output'];
  seller: User;
  sellerId: Scalars['String']['output'];
  sku: Sku;
  skuId: Scalars['String']['output'];
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<OrderEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** The node object (belonging to type Order) attached to the edge. */
  node: Order;
};

export type OrderInterface = {
  /** The amount paid by the user. Represented in cents (e.g. $10 is 1000) */
  amount: Scalars['Int']['output'];
  buyerId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The date the order was delivered to the buyer. */
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** Only visible to the seller, otherwise returns 0. Represented in cents (e.g. $10 is 1000) */
  feeAmount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  /** Only visible to the seller, otherwise returns 0. Represented in cents (e.g. $10 is 1000) */
  payoutAmount: Scalars['Int']['output'];
  /** Only visible to the seller, otherwise returns `null`. The date the payout is available to the seller. Returns `null` for physical goods that haven't delivered yet. */
  payoutAvailableAt?: Maybe<Scalars['DateTime']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  /** The amount of money refunded to the buyer. Represented in cents (e.g. $10 is 1000) */
  refundedAmount: Scalars['Int']['output'];
  sellerId: Scalars['String']['output'];
  skuId: Scalars['String']['output'];
};

export type OrdersFilter = {
  /** Only consider orders that occurred after a certain date. */
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Only consider orders the current user received from other users. */
  receivedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only consider orders made by the current user. */
  sentOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only consider orders that occurred before a certain date. */
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Post = PostInterface & {
  __typename?: 'Post';
  assetIds: Array<Scalars['String']['output']>;
  assets: Array<Asset>;
  commentCount: Scalars['Float']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  likeCount: Scalars['Float']['output'];
  /** Returns whether or not the current user liked the post. */
  liked: Scalars['Boolean']['output'];
  productIds: Array<Scalars['String']['output']>;
  products: Array<Product>;
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  tags: Array<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  user: User;
  userId: Scalars['String']['output'];
};

export type PostComment = PostCommentInterface & {
  __typename?: 'PostComment';
  canDelete: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** The parent postComment, if this is a threaded reply. */
  parent?: Maybe<PostComment>;
  /** The id of the parent postComment, if this is a threaded reply. */
  parentId?: Maybe<Scalars['String']['output']>;
  /** The postComment text, with encoded @ mentions. */
  text: Scalars['String']['output'];
  /** The user who authored the comment. */
  user: User;
  userId: Scalars['String']['output'];
};

export type PostCommentConnection = {
  __typename?: 'PostCommentConnection';
  edges: Array<PostCommentEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type PostCommentEdge = {
  __typename?: 'PostCommentEdge';
  /** The node object (belonging to type PostComment) attached to the edge. */
  node: PostComment;
};

export type PostCommentInterface = {
  canDelete: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** The id of the parent postComment, if this is a threaded reply. */
  parentId?: Maybe<Scalars['String']['output']>;
  /** The postComment text, with encoded @ mentions. */
  text: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type PostInterface = {
  commentCount: Scalars['Float']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  likeCount: Scalars['Float']['output'];
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export enum Presence {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type Product = ProductInterface & {
  __typename?: 'Product';
  assetIds: Array<Scalars['String']['output']>;
  assets: Array<Asset>;
  createdBy: User;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  skus: Array<Sku>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges: Array<ProductEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** The node object (belonging to type Product) attached to the edge. */
  node: Product;
};

export type ProductInterface = {
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  balanceSummary: BalanceSummary;
  conversation?: Maybe<Conversation>;
  /** Returns messages in a conversation for the current user's view. */
  conversationMessages: ConversationMessagesConnection;
  conversations: ConversationConnection;
  /** Must be the author of the post, otherwise `null` is always returned. Returns the post even if it is in draft form that the author can modify using `updatePost`. */
  editablePost?: Maybe<Post>;
  followers: FollowConnection;
  following: FollowConnection;
  /** Returns the top users in a given tag based on total likes. */
  leaderboard: Array<LeaderboardStat>;
  /** Returns the user being managed via the `x-managed-user-id` header. */
  managedUser?: Maybe<User>;
  /** Returns the currently authenticated user, even when managing another user using the `x-managed-user-id` header. Use `managedUser` to get the user being managed. */
  me: User;
  message?: Maybe<Message>;
  /** Global way of determining if there are any new messages that have come in since the last time they checked, without requiring each conversation to be checked individually. */
  messageSummary: MessageSummary;
  order?: Maybe<Order>;
  orders: OrderConnection;
  post?: Maybe<Post>;
  postComments: PostCommentConnection;
  product: Product;
  products: ProductConnection;
  searchPosts: Array<Post>;
  searchUsers: Array<User>;
  /** Returns `managedUser` if it exists, otherwise returns `me`. */
  selectedUser?: Maybe<User>;
  status: Scalars['String']['output'];
  suggestedUsers: Array<User>;
  tags: TagConnection;
  timeline: TimelineConnection;
  /** Find a user by id or username (case-insensitive) */
  user?: Maybe<User>;
  withdrawals: WithdrawalConnection;
};


export type QueryConversationArgs = {
  id: Scalars['String']['input'];
};


export type QueryConversationMessagesArgs = {
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConversationsArgs = {
  filters?: InputMaybe<Array<ConversationFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEditablePostArgs = {
  id: Scalars['String']['input'];
};


export type QueryFollowersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFollowingArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLeaderboardArgs = {
  tagId: Scalars['String']['input'];
};


export type QueryMessageArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<OrdersFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPostArgs = {
  id: Scalars['String']['input'];
};


export type QueryPostCommentsArgs = {
  id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchPostsArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchUsersArgs = {
  query: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTimelineArgs = {
  followingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWithdrawalsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RegisterUserInput = {
  displayName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type ReportPostCommentInput = {
  reason: Scalars['String']['input'];
};

export type ReportPostInput = {
  reason: Scalars['String']['input'];
};

export enum SellingStatus {
  Enabled = 'ENABLED',
  NotVerified = 'NOT_VERIFIED',
  Revoked = 'REVOKED'
}

export type SendMessageInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  conversationId: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type Sku = SkuInterface & {
  __typename?: 'Sku';
  canDownload: Scalars['Boolean']['output'];
  digitalAssetIds: Array<Scalars['String']['output']>;
  /** Note: `url` is only available if the user can download the sku, otherwise it is an empty string. */
  digitalAssets: Array<Asset>;
  id: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  /** Orders as buyer. Returns no orders if there's been no purchases, returns 1 if there was a purchase, or returns 2 if there was a purchase but then a refund. */
  ordersAsBuyer: Array<Order>;
  ordersAsBuyerIds: Array<Scalars['String']['output']>;
  price: Scalars['Int']['output'];
  product: Product;
  productId: Scalars['String']['output'];
  purchasedAt?: Maybe<Scalars['DateTime']['output']>;
  status: SkuStatus;
  title: Scalars['String']['output'];
};

export type SkuInterface = {
  id: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  productId: Scalars['String']['output'];
  status: SkuStatus;
  title: Scalars['String']['output'];
};

export enum SkuStatus {
  Discontinued = 'DISCONTINUED',
  Hidden = 'HIDDEN',
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK'
}

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  clientSecret: Scalars['String']['output'];
};

/** The type of Stripe checkout session to create. */
export enum StripeCheckoutSessionType {
  /** Embed a prebuilt payment form on your site using Stripe Checkout. https://docs.stripe.com/payments/accept-a-payment?platform=web&ui=embedded-form */
  EmbeddedForm = 'EMBEDDED_FORM',
  /** Redirect to a Stripe-hosted payment page using Stripe Checkout. https://docs.stripe.com/payments/accept-a-payment?platform=web&ui=stripe-hosted */
  StripeHostedPage = 'STRIPE_HOSTED_PAGE'
}

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  clientSecret: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TagConnection = {
  __typename?: 'TagConnection';
  edges: Array<TagEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  /** The node object (belonging to type Tag) attached to the edge. */
  node: Tag;
};

export type TimelineConnection = {
  __typename?: 'TimelineConnection';
  edges: Array<TimelineEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type TimelineEdge = {
  __typename?: 'TimelineEdge';
  /** The node object (belonging to type Post) attached to the edge. */
  node: Post;
};

export type UpdateConversationInput = {
  /** Marks the conversation as favorited (or unfavorited) for the current user. */
  favorited?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks the conversation as read (or unread) for the current user. */
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePostInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Schedule when the post should expire. Set to `null` to keep the post live indefinitely. */
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Schedule when the post should get published. Set to `null` to keep the post in a draft state in which no other users will see the post. */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSkuInput = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Represented in cents (e.g. $10 is 1000) */
  price?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<SkuStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  avatarAssetId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<Scalars['String']['input']>>;
  interests?: InputMaybe<Array<Scalars['String']['input']>>;
  organization?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Array<Scalars['String']['input']>>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type User = UserInterface & {
  __typename?: 'User';
  about?: Maybe<Scalars['String']['output']>;
  avatarAsset?: Maybe<Asset>;
  avatarAssetId?: Maybe<Scalars['String']['output']>;
  badges: Array<Scalars['String']['output']>;
  /** Whether the current user is following this user. */
  currentUserIsFollowing: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  educations: Array<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  interests: Array<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  /** Whether the current user is being followed by this user. */
  isFollowingCurrentUser: Scalars['Boolean']['output'];
  organization?: Maybe<Scalars['String']['output']>;
  presence: Presence;
  projects: Array<Scalars['String']['output']>;
  sellingStatus: SellingStatus;
  sellingStatusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  skills: Array<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UserInterface = {
  about?: Maybe<Scalars['String']['output']>;
  avatarAssetId?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  educations: Array<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  interests: Array<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  organization?: Maybe<Scalars['String']['output']>;
  projects: Array<Scalars['String']['output']>;
  sellingStatus: SellingStatus;
  sellingStatusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  skills: Array<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type WithdrawInput = {
  /** The amount (before fees) to withdraw. Represented in cents (e.g. $10 is 1000). */
  amount: Scalars['Int']['input'];
  /** The idempotency key for the withdrawal to prevent duplicate submissions. Must be a UUID. */
  idempotencyKey: Scalars['String']['input'];
  /** The url to redirect to after the withdrawal is complete. */
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  /** Represented in cents (e.g. $10 is 1000). */
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The fees charged. Represented in cents (e.g. $10 is 1000). */
  feeAmount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  /** The amount paid out after fees. Represented in cents (e.g. $10 is 1000). */
  payoutAmount: Scalars['Int']['output'];
};

export type WithdrawalConnection = {
  __typename?: 'WithdrawalConnection';
  edges: Array<WithdrawalEdge>;
  pageInfo: LimitOffsetPageInfo;
};

export type WithdrawalEdge = {
  __typename?: 'WithdrawalEdge';
  /** The node object (belonging to type Withdrawal) attached to the edge. */
  node: Withdrawal;
};

export type WithdrawalWithPayoutLink = {
  __typename?: 'WithdrawalWithPayoutLink';
  /** Represented in cents (e.g. $10 is 1000). */
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The fees charged. Represented in cents (e.g. $10 is 1000). */
  feeAmount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  /** The amount paid out after fees. Represented in cents (e.g. $10 is 1000). */
  payoutAmount: Scalars['Int']['output'];
  /** The payout link for the withdrawal. */
  payoutLink: Scalars['String']['output'];
};
